<route>
{
  "meta": {
    "permission": [
      "settings.add_company"
    ]
  }
}
</route>

<template>
  <div class="px-1 px-lg-2">
    <i-table
      app="settings.company"
      dontRemove
      dontEdit
      dontCreate
      dontSearch
      :headers="headers"
      :items="companies"
      :reload.sync="reload"
      :title="$tc('companies')"
    >
      <template v-slot:item.users="{ item }">
        <div class="users-gap">
          <v-chip
            v-for="user in item.users"
            small
            color="primary"
            :key="user.username"
          >
            {{ user.username }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.approved="{ item }">
        <v-col class="pt-0 text-center">
          <v-icon :color="item.approved ? 'secondary' : 'red'">
            {{ item.approved ? 'fa-check' : 'fa-times' }}
          </v-icon>
        </v-col>
      </template>
      <template v-slot:item.pk="{ item }">
        <i-btn
          class="mx-2 mb-1"
          classes="mx-1"
          outlined
          :color="'info'"
          :icon="'mdi-pencil'"
          :title="$tc('edit', 1)"
          @click="changeRoute({ pk: item.pk })"
          v-if="isAdmin"
        />
      </template>
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      reload: false,
      companies: []
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('company'),
          value: 'name'
        },
        {
          text: this.$t('address'),
          value: 'address'
        },
        {
          text: this.$t('city'),
          value: 'city'
        },
        {
          text: this.$t('state'),
          value: 'state'
        },
        {
          text: this.$t('zip_code'),
          value: 'zip_code'
        },
        {
          text: this.$t('approved'),
          align: 'center',
          value: 'approved'
        },
        {
          text: this.$tc('user', 1),
          value: 'users'
        },
        {
          text: this.$t('action'),
          value: 'pk'
        }
      ]
    },
    ...mapGetters({
      isAdmin: 'session/isAdmin',
      me: 'session/me',
      myCompanies: 'session/myCompanies'
    })
  },
  methods: {
    /**
     * CompaniesQuery
     * Método para consultar y filtrar las empresas a las que le pertenecen el
     * usuario que inició sesión.
     * Si el usuario isAdmin, muestra todas las empresas registradas.
     * Si el usuario tiene una o más empresas asociadas, mostrará solamente esos
     * registros siempre que no sea isAdmin.
     * Si el usuario no tiene empresas asociadas no se mostrará nada.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async CompaniesQuery() {
      const companies = await this.$api.company.list({})
      let results_companies = companies.data.results

      if (!this.isAdmin) {
        if (results_companies.length > 0 && this.myCompanies.length > 0) {
          results_companies = results_companies.filter((ar) =>
            this.myCompanies.find((rm) => rm.id === ar.pk)
          )
        } else {
          results_companies = []
        }
      }
      this.companies = results_companies
    },
    /**
     * changeRoute
     * Método para redireccionar al formulario de crear subdominio
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    changeRoute(item) {
      this.$router.push({
        name: 'company-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    }
  },
  mounted() {
    this.CompaniesQuery()
  }
}
</script>
<style lang="sass" scoped>
.users-gap
  display: flex
  flex-wrap: wrap
  gap: 5px
  padding: 8px 0
</style>
